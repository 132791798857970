/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
function importContainer(container) {
  let component;
  try {
    component = require(`./${process.env.GATSBY_LOCALE}/${container}`).default;
  } catch {
    component = require(`./default/${container}`).default;
  }
  return component;
}

const Asha = importContainer('asha');
const Blog = importContainer('blog');
const FAQ = importContainer('faq');
const Home = importContainer('home');
const PrivacyPolicy = importContainer('privacy');
const SpeechTherapyAssessment = importContainer('speechTherapyAssessment');
const Teletherapy = importContainer('teletherapy');
const Academy = importContainer('academy');
const ToS = importContainer('tos');

export {
  Asha,
  Blog,
  FAQ,
  Home,
  PrivacyPolicy,
  SpeechTherapyAssessment,
  Teletherapy,
  Academy,
  ToS,
};
