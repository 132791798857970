import React from 'react';
import { useIntlMessages } from '@kokolingo/localisation';

import {
  PageNew,
  TeletherapyGuarantee,
  TeletherapyHeader,
  TeletherapyIntro,
  TeletherapyPricing,
} from '../../../fragments';

const Teletherapy = () => {
  const messages = useIntlMessages();

  return (
    <PageNew
      title={messages.teletherapy}
      description=""
      headline=""
      wrapper={(props) => <div {...props} />}
    >
      <TeletherapyHeader />
      <TeletherapyIntro />
      <TeletherapyGuarantee />
      <TeletherapyPricing />
    </PageNew>
  );
};

export default Teletherapy;
